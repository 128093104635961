/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_cognito_identity_pool_id: "us-east-1:20d60d47-c554-4b91-ac8d-6224a3f0f0a7",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_V6IArnv4w",
  aws_user_pools_web_client_id: "7qvgmjsmoqn8joaj9fk3ucqiq2",
  oauth: {}
};

export default awsmobile;
